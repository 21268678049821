import { createStandaloneToast } from "@chakra-ui/react"
import { QueryClient } from "@tanstack/react-query"
import * as R from "ramda"
import * as Sentry from "@sentry/remix"

import { isClerkError } from "./isClerkError"
import { theme } from "./theme"

const toast = createStandaloneToast({
  theme,
})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      onError(err) {
        Sentry.captureException(err)

        console.error(err)

        if (R.has("message", err) && typeof err.message === "string") {
          toast({
            status: "error",
            title: err.message,
          })
        }
      },
    },
    mutations: {
      retry: 3,
      onError(err) {
        Sentry.captureException(err)

        if (isClerkError(err)) {
          err.errors.forEach(({ message }) => {
            toast({
              status: "error",
              title: message,
            })
          })
          return
        }

        if (R.has("message", err) && typeof err.message === "string") {
          toast({
            status: "error",
            title: err.message,
          })
          return
        }

        toast({
          status: "error",
          title: "Uh oh! Something went wrong.",
        })
      },
    },
  },
})
